export default {
  map(membershipPackage) {
    return {
      packageId: membershipPackage.package_id,
      packageType: membershipPackage.package_type,
      name: membershipPackage.package_name,
      charge: getCharge(membershipPackage),
      chargeInterval: membershipPackage.charge_interval,
      delayPayment: membershipPackage.delay_payment,
      delayValue: membershipPackage.delay_value,
      agreementId: membershipPackage.membership_agreement_id,
      joiningFee: getFeeAmountByType(membershipPackage.fees, 'joining_fee'),
      joiningFeeText: membershipPackage.joining_fee_text,
      keyFob: getFeeAmountByType(membershipPackage.fees, 'key_fob'),
      contractDescription: membershipPackage.contract_description,
      contractLengthValue: membershipPackage.contract_length_value,
      contractLengthUnit: membershipPackage.contract_length_unit,
      paymentDays: membershipPackage.payment_days,
      paymentDaysOfWeek: membershipPackage.payment_days_of_week,
      ageLimit: membershipPackage.age_limit,
      isMPay: membershipPackage.is_mpay,
      paymentModes: membershipPackage.payment_modes,
      ongoingPaymentProvider: membershipPackage.ongoing_payment_provider,
      isGlobalSolutionDDMS: membershipPackage.is_global_solution_ddms,
      oneOffPaymentProvider: membershipPackage.one_off_payment_provider,
      skipOngoingCharge: membershipPackage.skip_ongoing_charge_online,
      ...(membershipPackage.promo_code && { promoCode: membershipPackage.promo_code }),
      ...(membershipPackage.referral_code && { referralCode: membershipPackage.referral_code }),
      ...(membershipPackage.discount_id && { discountId: membershipPackage.discount_id }),
      membershipSetupTemplateName: mapMembershipSetupTemplateName(membershipPackage.membership_setup_template_name),
      status: membershipPackage.status
    };
  }
};

function getCharge(membershipPackage) {
  const packageType = membershipPackage.package_type;

  if (packageType === 'ongoing') {
    return { formatted: membershipPackage.ongoing_charge_fmt, amount: membershipPackage.ongoing_charge };
  }
  if (packageType === 'pif') {
    return { formatted: membershipPackage.pif_charge_fmt, amount: membershipPackage.pif_charge };
  }
}

function getFeeAmountByType(fees, feeType) {
  if (!fees) {
    return;
  }

  const fee = fees.find(fee => fee.fee_type === feeType);

  if (fee) {
    return {
      formatted: fee.fee_amount_fmt,
      amount: fee.fee_amount
    };
  }
}

function mapMembershipSetupTemplateName(templateName) {
  let mappedTemplateName = templateName;
  if (mappedTemplateName === 'pif-with-payment-day') {
    mappedTemplateName = 'pif';
  }

  return mappedTemplateName;
}
